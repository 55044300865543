import(/* webpackMode: "eager", webpackExports: ["ModifierClassProvider"] */ "/app/apps/web/src/components/modifier-class-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/components/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/apps/web/src/components/shadcn-ui/sonner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TRPCReactProvider"] */ "/app/apps/web/src/lib/trpc.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/apps/web/src/providers/posthog-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Provider","Root","Trigger","Portal","Content","displayName"] */ "/app/node_modules/.pnpm/@radix-ui+react-tooltip@1.1.2_@types+react-dom@18.3.0_@types+react@18.3.4_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@radix-ui/react-tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/app/node_modules/.pnpm/next-client-cookies@1.1.1_next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next-runtime-env@3.2.2_next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/app/node_modules/.pnpm/next-themes@0.3.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"variable\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../fonts/CalSans-SemiBold.woff2\",\"weight\":\"600\",\"variable\":\"--font-cal-sans\"}],\"variableName\":\"calSans\"}");
